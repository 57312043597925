import '../css/app.pcss'
import router from './router'
import Nav from './components/Nav'
import Swup from './components/Swup'
import Preloader from './components/Preloader'

const nav = new Nav(document.querySelector('.top-bar'))
nav.init()
const preloader = new Preloader()
Swup(nav, preloader)
window.addEventListener('load', () => {
    preloader.open()
    router()
  }
)
